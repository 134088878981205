import axios, { AxiosRequestConfig } from 'axios';
import KeycloakService from './KeycloakService';

const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT'
};

const _axios = axios.create();

const configure = () => {
    _axios.interceptors.request.use((config: AxiosRequestConfig) => {
        if (KeycloakService.isLoggedIn()) {
            const cb = () => {
              sessionStorage.setItem('react-token', KeycloakService.getToken() || 'no');
                if(config && config.headers) {
                    config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
                    return Promise.resolve(config);
                }
            };
            return KeycloakService.updateToken(cb);
        }
    })
}

const getAxiosClient = () => _axios;

const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient
};

export default HttpService;
