import { Button, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import Sidemenu from '../../components/Sidemenu';
import { DataGrid, GridColDef, gridDateFormatter, GridSelectionModel } from "@mui/x-data-grid";
import HttpService from "../../services/HttpService";
import { ReactComponent as UserImg } from './../../assets/images/user.svg';
import moment from "moment";
import ReferralConflicts from "./referralconflicts";
import './conflicts.css';
import InstaConflicts from "./instaconflicts";
import InstaReferralConflicts from "../instaReferralConflicts";
import { GRADIENTIMAGECOLOR } from "../../utils/constants";

const _axios = HttpService.getAxiosClient();

const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
        backgroundImage: GRADIENTIMAGECOLOR,
    }
}));

const conflicts_tabs = ['Referral Conflicts', 'Insta Conflicts']
const ReferrerPatientConflicts = () => {
    const classes = useStyles();
    let selectedRows: GridSelectionModel;

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [aciveTab, setActiveTab] = React.useState(0);

    const handleTabClick = (val: string, index: number) => {
        setActiveTab(index)
    }


    return (
        <>
            <Sidemenu />
            <div className="conflictsDiv">
                <Snackbar
                    ContentProps={{
                        "aria-describedby": "message-id",
                        className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
                <div className="conflicts_tabs">
                    <ul>
                        {conflicts_tabs.map((d, i) => <li key={i} className={`${aciveTab === i ? 'activeTab' : ''}`} onClick={() => handleTabClick(d, i)}>{d}</li>)}
                    </ul>
                </div>
                <div
                    className="layoutContent">
                    {aciveTab === 0 && <ReferralConflicts />}
                    {aciveTab === 1 && <InstaConflicts />}
                    {aciveTab === 2 && <InstaReferralConflicts />}
                </div>
            </div>
        </>
    );
}

export default ReferrerPatientConflicts;