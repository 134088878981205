import React, { useEffect, useState } from "react";
import Sidemenu from "../components/Sidemenu";
import { Snackbar, Typography, Grid, TextField, Button } from "@mui/material";
import HttpService from "../services/HttpService";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import DisputePaymentApprovalMD from "./paymentApprovalMDDispute";
import {
  FORMID,
  GRADIENTIMAGECOLOR,
  MID_URL_FORM_RUNTIME_DATA,
} from "../utils/constants";
import "./conflicts/conflicts.css";
import { reportDataObj , docsData} from "../types/Module_Type";

const _axios = HttpService.getAxiosClient();

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  },
}));

const tabsName = ["Payment Approval", "Dispute Payment Approval"];
const tableHead = [
  "MR",
  "Referrer Name",
  "Referrer Mobile",
  "Patient Name",
  "Date of Discharge",
  "Referrer Amount",
  "Amount Modified",
  "Finalised Amount",
  "Status",
];

const columns: GridColDef[] = [
  { field: "MR_NO", headerName: "MR Number", flex: 1, headerClassName: "aaa" },
  { field: "referrerName", headerName: "Referrer Name", flex: 1 },
  { field: "patientName", headerName: "Patient Name", flex: 1 },
  { field: "patientPhone", headerName: "Patient Mobile", flex: 1 },
  { field: "dateOfDischarge", headerName: "Date of Discharge", flex: 1 },
  { field: "amountCalculated", headerName: "Amount Calculated ", flex: 1 },
  { field: "amountModified", headerName: "Amount Modified", flex: 1 },
  {
    field: "viewCalculation",
    headerName: "View Calculation",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const onClick = (e: any) => {
        const currentRow = params.row;
        return alert(JSON.stringify(currentRow, null, 4));
      };
      return (
        <>
          <Button
            variant="outlined"
            color="warning"
            size="small"
            onClick={onClick}
          >
            View
          </Button>
        </>
      );
    },
  },
];

const PaymentApprovalbyDirector = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [rows, setRows] = React.useState<any[]>([]);
  const [docArray, setDocArray] = useState<docsData[]>([]);
  const [viewReport, setViewReport] = useState(false);
  const [reportData, setReportData] = useState<reportDataObj | null>(null);
  const [finaliseAmount, setFinaliseAmount] = useState("");
  const [finAmtDesc, setFinAmtDesc] = useState("");
  const [aciveTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    fetchAllModifiedList();
  }, []);

  const fetchAllModifiedList = async () => {
    try {
      let res = await _axios.get(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?page=0&size=10000&formId=1030055364952023040&filter=formData.modified:true;formData.fileGenerationStatus:false`
      );
      if (res && res.data.success) {
        if (res?.data?.data?.content?.length > 0) {
          // setRows(data => res.data.data.map((d: any) => ({ id: d.id, ...d.formData })));
          setDocArray(
            res.data.data.content.map((d: any) => ({ id: d.id, ...d.formData }))
          );
        }
      }
    } catch (e: any) {
      console.log(e);
      setMessage(e.message);
      setOpen(true);
    }
  };

  const handleSingleSelect = (data: any) => {
    console.log(data);
  };

  const handleApprove = async (data: any, index: any) => {
    try {
      const payload = {
        id: data.id,
        formId: FORMID.paymentAcceptanceTranscationId,
        formData: {
          paymentAcceptanceStatus: "approved",
          approvedBy: localStorage.getItem("userRole"),
          finalisedAmount: finaliseAmount,
          finalisedDescription: finAmtDesc,
        },
      };
      let resp = await _axios.patch(
        `${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`,
        payload
      );
      if (resp.data.success) {
        setFinaliseAmount("");
        setFinAmtDesc("");
        setMessage("Referrer Amount Approved.");
        setOpen(true);
        setViewReport(false);
        setTimeout(() => {
          fetchAllModifiedList();
        }, 1500);
      }
    } catch (e: any) {
      console.log(e);
      setMessage(e.message);
      setOpen(true);
    }
  };

  const handleGeneratePayment = async () => {
    let newDate = new Date();
    try {
      let count = 0;
      docArray.forEach(
        (data) => data.paymentAcceptanceStatus === "" && count++
      );
      if (count > 0) {
        console.log(
          "All date are not processed, Please Approve / modify data to generate Payment"
        );
      } else {
        const payload = {
          processDefinitionKey: "Process_vshpz1m",
          businessKey: "rfm-file",
          variables: {
            to: process.env.REACT_APP_PAYMENT_EMAIL,
            generatedBy: localStorage.getItem("userName"),
            approvedBy: localStorage.getItem("userRole"),
            dateOfDischarge: converDate(newDate),
          },
        };
        console.log(payload);
        let resp = await _axios.post(
          `${process.env.REACT_APP_MAIN_SERVER_URL}workflow/v1/process/start`,
          payload
        );
        setMessage("Payment File Generated, Process started successfully");
        setOpen(true);
        setTimeout(() => {
          fetchAllModifiedList();
        }, 1500);
      }
    } catch (e: any) {
      console.log(e);
      setMessage(e.message);
      setOpen(true);
    }
  };

  const converDate = (str: any) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const setDateFormat = (date: String) => {
    let dateWithOutString = date.replace(/"/gi, "");
    dateWithOutString = moment(dateWithOutString).format("DD-MMM-YYYY / hh:mm");
    return dateWithOutString;
  };

  const handleViewReport = (d: any, i: any) => {
    setViewReport(true);
    setReportData(d ?? {});
    setFinaliseAmount(d.amountModified);
  };
  const closeWindow = () => {
    setViewReport(false);
  };

  const handleFinaliseAmount = (e: any) => {
    setFinaliseAmount(e.target.value);
  };
  const handleDescription = (e: any) => {
    setFinAmtDesc(e.target.value);
  };

  const getExcludingData = (excludingData: any) => {
    let newArray = Object.keys(excludingData).map((name) => ({
      name,
      value: excludingData[name],
    }));
    return newArray.map((arObj) => (
      <tr>
        <td className="text-right">{arObj.name} :</td>
        <td className="text-right">{arObj.value}</td>
      </tr>
    ));
  };

  const handleTabClick = (val: string, index: number) => {
    setActiveTab(index);
  };

  return (
    <>
      <Sidemenu />
      <div className="layoutParent">
        <p className="layoutParentP">Payment Approval</p>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps,
          }}
          open={open}
          autoHideDuration={3000}
          message={message}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
        <div className="layoutContent">
          <div className="conflicts_tabs">
            <ul>
              {tabsName.map((d, i) => (
                <li
                  className={`${aciveTab === i ? "activeTab" : ""}`}
                  onClick={() => handleTabClick(d, i)}
                >
                  {d}
                </li>
              ))}
            </ul>
          </div>

          {viewReport && (
            <>
              <div className="overlay" onClick={closeWindow}></div>
              <div className="modalPopup modalPopupw heightWScroll">
                <h4>Payment Approval Report</h4>
                <table className="data-grid-table customPading">
                  <tbody>
                    <tr>
                      <td className="text-right">Net Amount: </td>
                      <td className="text-right">{reportData?.netAmount}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="text-center">
                        <b className="color444">
                          {" "}
                          Amount after excluding bills
                        </b>
                      </td>
                    </tr>
                    {getExcludingData(reportData?.amountCalculation)}
                    <tr>
                      <td className="text-right">
                        Amount Calculated after excluding above bills
                      </td>
                      <td className="text-right">
                        {reportData?.amountCalculated}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right">
                        Percentage of referrer on calculated amount
                      </td>
                      <td className="text-right">
                        {reportData?.percentageOfReferrer}%
                      </td>
                    </tr>
                    <tr>
                      <td className="text-right">
                        <b>Total</b>
                      </td>
                      <td className="text-right">
                        <b>{reportData?.referrerAmount}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="amountView">
                  <p>
                    Referrer Amount: <span>{reportData?.referrerAmount}</span>
                  </p>
                  <p>
                    Modified Amount: <span>{reportData?.amountModified}</span>
                  </p>
                </div>
                <div className="approvalComment">
                  <label>Finalised Amount: </label>
                  <input
                    type="text"
                    value={finaliseAmount}
                    onChange={handleFinaliseAmount}
                    placeholder="Enter Finalised Amount"
                  />
                  <label>Comment / Description</label>
                  <textarea
                    className="verticalTop"
                    onChange={handleDescription}
                    placeholder="Enter Comment / Desciption"
                  ></textarea>
                  <div className="text-center">
                    <button onClick={() => handleApprove(reportData, 0)}>
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {aciveTab === 0 ? (
            <div>
              <table className="data-grid-table">
                <thead>
                  <tr>
                    {tableHead.map((d) => (
                      <th>{d}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {docArray.length > 0 ? (
                    docArray.map((d, i) => (
                      <tr>
                        <td>{d.MR_NO}</td>
                        <td>{d.referrerName}</td>
                        <td>{d.referrerMobileNumber}</td>
                        <td>{d.patientName}</td>
                        <td>{setDateFormat(d.dateOfDischarge)}</td>
                        <td className="text-center">{d.referrerAmount}</td>
                        <td>
                          {d.modified ? (
                            <div className="modfiedTextAmt">
                              <span className="diff">
                                {d.amountModified}
                                <InfoIcon className="iconInfo" />
                                <span className="descToolTip">
                                  {d.description}
                                </span>
                              </span>
                            </div>
                          ) : null}
                        </td>
                        <td className="text-center">
                          {d.finalisedAmount  ?  d.finalisedAmount :"-"}
                        </td>
                        <td>
                          {/* {d.finalisedAmount  && <span><button onClick={() => handleViewReport(d, i)} className={`tableApproveBtn ${d.paymentAcceptanceStatus === 'approved' ? 'disableAprove' : ''}`}>Approve</button></span>}
                                            {d.paymentAcceptanceStatus === 'approved' && <span>Approved</span>} */}
                          {d.finalisedAmount  ? (
                            <button
                              onClick={() => handleViewReport(d, i)}
                              className={`tableApproveBtn`}
                            >
                              Approve
                            </button>
                          ) : (
                            "Approved"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={9}>
                        No Data for the selected Date
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="flex-center">
                {docArray.length > 0 &&
                  docArray.filter((d) => d.finalisedAmount > 0)
                    .length === docArray.length && (
                    <Button
                      className="gradientBtn"
                      onClick={handleGeneratePayment}
                    >
                      Generate Payment File
                    </Button>
                  )}
              </div>
            </div>
          ) : (
            ""
          )}

          {aciveTab === 1 && <DisputePaymentApprovalMD />}
        </div>
      </div>
    </>
  );
};

export default PaymentApprovalbyDirector;
