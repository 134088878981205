import React from "react";

const InstaReferralConflicts = () => {
    return(
        <div>
            InstaReferralConflicts
        </div>
    )
}

export default InstaReferralConflicts;