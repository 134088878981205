import React, { ReactElement, FC, useEffect } from "react";
import { Box, Grid, TextField, Typography, CircularProgress, InputAdornment,
  FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { styled } from '@mui/material/styles';
import Sidemenu from '../components/Sidemenu';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import HttpService from "../services/HttpService";
import KeycloakService from "../services/KeycloakService";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import {specialCharValidation, emailValidation, VERSION} from '../constants';
import { GRADIENTIMAGECOLOR } from "../utils/constants";

const _axios = HttpService.getAxiosClient();
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));

const useStyles = makeStyles(() => ({
  inputField: {
     [`& fieldset`]: {
           borderRadius: 15,
     },
  },
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  }
}));

export type UserDetails = {
    ifscCode: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    mobileNumber: string;
    accountNumber: string;
    accountHolderName: string;
    bankDetails:{BRANCH: string, ADDRESS: string};
};

export type userCompleteData = {
  userId?: string;
  userData?:{}
}

const getBankDetailsURL = 'https://ifsc.razorpay.com/'

const ProfileSettings: FC<any> = (): ReactElement => {
  const classes = useStyles();
  // const [ifsc, setIFSC] = React.useState("");
  // const [firstName, setFirstName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");
  // const [email, setEmail] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // const [address, setAddress] = React.useState("");
  const [errorState, setErrorState] = React.useState("");
  // const [mobileNumber, setMobileNumber] = React.useState("");
  // const [accountNumber, setAccountNumber] = React.useState("");
  // const [accountHolderName, setAccountHolderName] = React.useState("");
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [bankDetailFetch, setBankDetailFetch] = React.useState(false);
  // const [bankDetails, setBankDetails] = React.useState({BRANCH: '', ADDRESS: ''});
  const tempDetails:UserDetails = {
    ifscCode: '',
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    mobileNumber: '',
    accountNumber: '',
    accountHolderName: '',
    bankDetails:{BRANCH: '', ADDRESS: ''}
  }
  const [ fullUserData, setFullUserData ] = React.useState<UserDetails>(tempDetails);
  const tempUserData:userCompleteData = {
    userId: '',
    userData:{}
  }
  const [ user, setUser] = React.useState<userCompleteData>(tempUserData);

  const verifyIFSC = async () => {
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if(fullUserData?.ifscCode && regex.test(fullUserData?.ifscCode)) {
      setBankDetailFetch(true);
      const bdResponse = await axios.get(`${getBankDetailsURL}${fullUserData.ifscCode}`);
      if(bdResponse.data)
      // setUserInfo({ ...userInfo, ifscCode: ifscCode });
      setFullUserData({...(fullUserData as UserDetails), bankDetails: { BRANCH: bdResponse.data.BRANCH, ADDRESS: bdResponse.data.ADDRESS}});
        // setBankDetails({BRANCH: bdResponse.data.BRANCH, ADDRESS: bdResponse.data.ADDRESS});
      setBankDetailFetch(false);
    } else {
      setOpen(true);
      setSnackBarMessage('Invalid, please send valid IFSC');
    }
  }

  const submitDataValidation = () => {
    if(!fullUserData.firstName) return 'firstName'; if(!fullUserData.lastName) return 'lastName';
    if(!emailValidation(fullUserData.email)) return 'email';
    // if(mobileNumber[0] !== '+' || SBIN0070987
    //     Number.isNaN(Number(mobileNumber.slice(0))) || 
    //     mobileNumber.length < 4) return 'mobileNumber';
    if(!fullUserData.address) return 'address';
    if(!fullUserData.accountHolderName && !fullUserData.firstName) return 'accountHolderName';
    if(!fullUserData.accountNumber) return 'accountNumber';
    if(!fullUserData.ifscCode) return 'ifsc';
    if(!fullUserData.bankDetails.BRANCH) return 'bankdetails';
}

  const saveUserDetails = async () => {
    try{
      const errorInformed = submitDataValidation();
      if(errorInformed) {
        setErrorState(errorInformed);
        if(errorInformed === 'bankdetails') {
          setOpen(true);
          setSnackBarMessage('Please Enter Proper IFSC Code and verify bank details');
        }
        return;
      }
      
      const payload = {
        "intent": "getUserByUserId",
        "state": {
            userId : KeycloakService.getSub(),
        }
      }
      
      const saveResponse = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`, payload, {
        headers: {
          version: VERSION,
          }
      },)

      if(saveResponse && saveResponse.data && saveResponse.data.success) {
        setOpen(true);
        setSnackBarMessage(saveResponse.data.message);
      }
    }
    catch (e:any) {
      console.log(e);
      setSnackBarMessage(e.message);
      setOpen(true);
    }
    
  }

  const navigate = useNavigate();

  const getUserData = async () => {

    const payload = {
      "intent": "getUserByUserId",
      "state": {
          userId : KeycloakService.getSub(),
      }
    }
    
    const responseObj = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}${process.env.REACT_APP_PROJECT_ID}`, payload, {
      headers: {
        version: VERSION,
        }
    },)

    if(!responseObj?.data){
      if(KeycloakService.isLoggedIn()) {
        navigate('/signup');
      }
    }

    return responseObj;
  };

  useEffect(() => {
    const checkKeycloakActive = async () => {
      const res = await getUserData();
      if(res?.data?.data.length > 0) {
        // setFullUserData(userData?.data?.data[0])
        setFullUserData(data => {
          setUser(res?.data?.data[0])
          const userData = res?.data?.data[0].userData;
          return {
            ...data,
            hospitalType: userData.hospitalType,
            userName: userData.userName,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            address: userData.address,
            accountHolderName: userData.accountHolderName,
            accountNumber: userData.accountNumber,
            ifscCode: userData.ifscCode,
            mobileNumber:userData.mobileNumber
        }
        
      }
      );
        
        // setMobileNumber(userData?.data?.data[0]?.userData.userName);
        // setFirstName(userData?.data?.data[0]?.userData.firstName);
        // setLastName(userData?.data?.data[0]?.userData.lastName);
        // setEmail(userData?.data?.data[0]?.userData.emailId);
        // setAddress(userData?.data?.data[0]?.userData.address)
        // setAccountHolderName(userData?.data?.data[0]?.userData.accountHolderName)
        // setAccountNumber(userData?.data?.data[0]?.userData.accountNumber)
        // setIFSC(userData?.data?.data[0]?.userData.ifscCode)
      } else {
        navigate('/signup');
      }
    }
    if(KeycloakService.getUsername()) checkKeycloakActive();
  }, [fullUserData.email]);

  return (
    <>
        <Sidemenu />
        <div className="layoutParent">
          <p className="layoutParentP">Profile Settings</p>
          <Box
           className="layoutContent"
            sx={{
              flexGrow: 1,
              width: '86vw',
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: 'column'
            }}
          >
            <div style={{paddingTop: '7vh'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} className="txt-algn-right">
                  <Item>
                      <TextField
                        id="firstName"
                        label="Firstname"
                        className={`width300 ${classes.inputField}`}
                        value={fullUserData.firstName}
                        disabled
                        error={errorState === 'firstName' ? true : false}
                        onChange={(e) => {
                          if(!specialCharValidation(e.target.value)) return false;
                          setFullUserData({...fullUserData, firstName: e.target.value})
                        }}
                      />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="txt-algn-left">
                  <Item>
                    <TextField
                        id="lastname"
                        label="Lastname"
                        className={`width300 ${classes.inputField}`}
                        value={fullUserData.lastName}
                        disabled
                        error={errorState === 'lastName' ? true : false}
                        onChange={(e) => {
                          if(!specialCharValidation(e.target.value)) return false;
                          setFullUserData({...fullUserData, lastName: e.target.value})
                        }}
                      />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="txt-algn-right">
                  <Item>
                      <TextField
                        id="email"
                        label="Email"
                        disabled
                        className={`width300 ${classes.inputField}`}
                        value={fullUserData.email}
                        error={errorState === 'email' ? true : false}
                        onChange={(e) => setFullUserData({...fullUserData, email: e.target.value})}
                      />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className="txt-algn-left">
                  <Item>
                    <TextField
                      id="phn"
                      label="Phone"
                      disabled
                      inputProps={{ maxLength: 13 }}
                      className={`width300 ${classes.inputField}`}
                      value={fullUserData.mobileNumber}
                      error={errorState === 'mobileNumber' ? true : false}
                      onChange={(e) => {
                        const regex = /^[0-9+\b]+$/;
                        if(!regex.test(e.target.value)) return false;
                        // setMobileNumber(e.target.value)
                        setFullUserData({...fullUserData, mobileNumber: e.target.value})
                      }}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} className="text-center">
                  <Item>
                    <TextField
                      id="addr"
                      label="Address"
                      inputProps={{ maxLength: 150 }}
                      className={`width630 ${classes.inputField}`}
                      error={errorState === 'address' ? true : false}
                      value={fullUserData.address}
                      onChange={(e) => setFullUserData({...fullUserData, address: e.target.value})}
                    />
                  </Item>
                </Grid>
              </Grid>
            </div>
            <Snackbar
              open={open}
              ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyleViaContentProps
              }}
              autoHideDuration={3000}
              message={snackBarMessage}
              onClose={() => setOpen(false)}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            />
            <Card sx={{ width: 680, height: '3 4vh', backgroundColor: "white", marginTop: '2vh'}}>
              <CardContent>
                <Typography sx={{ textAlign: 'center', color: '#CF3438', marginBottom: '2vh' }}>
                  BANK DETAILS
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4} className="text-center">
                    <Item>
                      <TextField
                          id="acHolderName"
                          label="Account Holder Name"
                          className={classes.inputField}
                          inputProps={{ maxLength: 80 }}
                          error={errorState === 'accountHolderName' ? true : false}
                          onChange={(e) => {
                            if(!specialCharValidation(e.target.value)) return false;
                            setFullUserData({...fullUserData, accountHolderName: e.target.value})
                          }}
                          value={fullUserData.accountHolderName}
                        />
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} className="text-center">
                    <Item>
                      <TextField
                          id="acNumber"
                          label="Account Number"
                          className={classes.inputField}
                          value={fullUserData.accountNumber}
                          error={errorState === 'accountNumber' ? true : false}
                          inputProps={{ maxLength: 20 }}
                          onChange={(e) => {
                            setFullUserData({...fullUserData, accountNumber: e.target.value})
                          }}
                          onBlur={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if(!regex.test(e.target.value)) return false;
                          }}
                        />
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} className="text-center">
                    <Item>
                        <FormControl className={classes.inputField} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-ifsc">IFSC Code</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-ifsc"
                            value={fullUserData.ifscCode}
                            error={errorState === 'ifsc' ? true : false}
                            onChange={(e) => {
                              const regex = /^[A-Z0-9\b]+$/;
                              if(!regex.test(e.target.value)) return false;
                              setFullUserData({...fullUserData, ifscCode: e.target.value})
                            }}
                            endAdornment={
                              <InputAdornment position="end"><Button onClick={verifyIFSC} sx={{color: '#CF3438'}}>Verify</Button></InputAdornment>
                            }
                            label="IFSC Code"
                          />
                        </FormControl>
                    </Item>
                  </Grid>
                </Grid>
                {
                  bankDetailFetch ? 
                    <CircularProgress />
                  : null
                }
                {
                    fullUserData.bankDetails.ADDRESS ? 
                    <>
                        <Typography sx={{ textAlign: 'center', color: '#CF3438', marginBottom: '2vh', marginTop: '2vh' }}>{fullUserData.bankDetails.BRANCH}</Typography>
                        <Typography sx={{ textAlign: 'center', color: '#CF3438', marginBottom: '2vh' }}>{fullUserData.bankDetails.ADDRESS}</Typography>
                    </>
                   : null
                }
              </CardContent>
            </Card>
            <div>
                <div className="flex-center">
                  <Button className="gradientBtn" onClick={saveUserDetails}> Save </Button>
                </div>
            </div>         
          </Box>
        </div>
    </>
  );
};

export default ProfileSettings;