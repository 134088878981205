import React, { useEffect, useState } from "react";
import Sidemenu from '../components/Sidemenu';
import { makeStyles } from "@mui/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Snackbar, Typography, Grid, TextField, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from "moment";
import HttpService from "../services/HttpService";
import DisputePaymentApproval from "./paymentApprovalDispute";
import { smsService } from "../services/smsService";
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA } from "../utils/constants";
import { reportDataObj , docsData} from "../types/Module_Type";

const _axios = HttpService.getAxiosClient();
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
        backgroundImage: GRADIENTIMAGECOLOR,
    }
}));

const tabsName = ['Payment Approval', 'Dispute Payment Approval'];

const tableHead = ['MR NO', 'Referrer Name', 'Referrer Mobile', 'Patient Name', 'Date of Discharge', 'Referrer Amount', 'Amount Modified', 'View Calculation', 'Action']


const PaymentApproval = () => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [docArray, setDocArray] = useState<docsData[]>([]);
    const [adminDate, setAdminDate] = useState<Date | null>(null);
    const [amountModified, setAmountModified] = useState('');
    const [description, setDescription] = useState('');
    const [viewReport, setViewReport] = useState(false);
    const [reportData, setReportData] = useState<reportDataObj | null>(null);
    const [aciveTab, setActiveTab] = React.useState(0);

    const handleModify = (data: any, index: number) => {
        let docData = [...docArray];
        docData[index].editField = true;
        setDocArray(docData);
    }
    const cancelModify = (index: number) => {
        let docData = [...docArray];
        docData[index].editField = false;
        setDocArray(docData);
    }

    const handleInputAmt = (e: any) => {
        setAmountModified(e.target.value);
    }
    const handleInputDesc = (e: any) => {
        setDescription(e.target.value);
    }


    const converDate = (str: any) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const fetchAllData = async (date: any) => {
        converDate(date);
        try {
            let resp = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?page=0&size=10000&formId=1030055364952023040&filter=formData.dateOfDischarge:${converDate(date)};formData.fileGenerationStatus:false`);
            setDocArray(resp.data.data.content.map((d: any) => ({ id: d.id, ...d.formData })));
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const getDataForFinance = async () => {
        const download_date = moment(new Date(Date.now() - 864e5), "YYYY-MM-DD").format('YYYY-MM-DD');
        let admin_date = moment(adminDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let finalDate = '';
        adminDate ?
            finalDate = admin_date : finalDate = download_date
        if (finalDate.slice(5, 6) === '0') {
            finalDate = finalDate.slice(0, 5) + finalDate.slice(6, finalDate.length);
        }
        if (finalDate.slice(7, 8) === '0') {
            finalDate = finalDate.slice(0, 7) + finalDate.slice(8, finalDate.length);
        }
        const financeData = await dateFilterData(finalDate);
        if (financeData && financeData.data.success) {
            if (financeData.data.data.content.length >= 1) {
                const documentId = financeData.data.data.content[0].id;
                // handleDownload(documentId, true);
            } else {
                // setTransactionStatus('');
                // setTransactionDate(null);
                setMessage('Sorry data not available for the date');
                setOpen(true);
            }
        }
    }

    const dateFilterData = (filterDate: string) => {
        const requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}dms/v1/documents?include-sub-folders=true&filter=fileName:"payment_list_ ${filterDate}.txt"`;
        return _axios.get(requestURL);
    }

    const setDateFormat = (date: String) => {
        let dateWithOutString = date.replace(/"/gi, "");
        dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
        return dateWithOutString;
    }

    const submitModifiedAmt = async (data: any, index: any) => {
        try {
            if (description.length === 0 && amountModified.length === 0) {
                setMessage('Amount and description is required.!');
                setOpen(true);
                return false;
            }
            const payload = {
                id: data.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    description: description,
                    amountModified: amountModified,
                    modified: true,
                    paymentAcceptanceStatus: 'modified',
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            setMessage('Amount and description is modified');
            setOpen(true);
            if (resp.data.success) {
                setTimeout(() => {
                    cancelModify(index);
                    setAmountModified('');
                    setDescription('');
                    fetchAllData(adminDate)
                }, 1500)
                smsService(`managingDirector`,`Dear ${localStorage.getItem('MDName')}, mananger with name ${data.managerName} has modified the payment from ${data.referrerAmount} to ${amountModified}.`)
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const callSmsService = async(msg:string) => {
        try{
            const payload = 
            { 
                "operationName": "SEND_SMS", 
                "variables": { 
                    "mailMessage": { 
                        "to": '918688552175', 
                        "from": "kims", 
                        "body": msg
                    } 
                },
                "query": "mutation SEND_SMS($mailMessage: smsMessage!) {\n  sendSMS(input: $mailMessage)\n}\n" 
            }
            let resp = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}notification/v1/graphql`, payload);
        }
        catch(e){

        }
    }

    const handleApprove = async (data: any, index: any) => {
        try {
            const payload = {
                id: data.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    paymentAcceptanceStatus: 'approved',
                    approvedBy: localStorage.getItem('userRole'),
                    finalisedAmount: data.referrerAmount
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
        
            if (resp.data.success) {
                setTimeout(() => {
                    setMessage('Referrer Amount Approved.');
                    setOpen(true);
                    fetchAllData(adminDate);
                }, 1500)

            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const handleGeneratePayment = async () => {
        try {
            let count = 0;
            docArray.forEach((data) => data.paymentAcceptanceStatus === '' && count++);
            if (count > 0) {
                setMessage('All data are not processed, Please Approve / modify data to generate Payment');
                setOpen(true);
            }
            else {
                const payload = {
                    processDefinitionKey: "Process_vshpz1m",
                    businessKey: "rfm-file",
                    variables: {
                        to: process.env.REACT_APP_PAYMENT_EMAIL,
                        generatedBy: localStorage.getItem('userName'),
                        dateOfDischarge: converDate(adminDate),
                        approvedBy: localStorage.getItem('userRole')
                    }
                }
                let resp = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}workflow/v1/process/start`, payload);
                setMessage('Payment File Generated, Process started successfully');
                setOpen(true);
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const handleViewReport = (d: any, i: any) => {
        setViewReport(true);
        setReportData(d ?? {})
    }
    const closeWindow = () => {
        setViewReport(false);
    }

    const getExcludingData = (excludingData: any) => {
        let newArray = Object.keys(excludingData).map(name => ({ name, value: excludingData[name] }))
        return (
            newArray.map(arObj => <tr><td className="text-right">{arObj.name} :</td><td className="text-right">{arObj.value}</td></tr>)
        )
    }

    const handleTabClick = (val: string, index: number) => {
        setActiveTab(index)
    }

    return (
        <>
            <Sidemenu />
            <div className="layoutParent">
                <p className="layoutParentP">Payment Approval</p>
                <Snackbar
                    ContentProps={{
                        "aria-describedby": "message-id",
                        className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
                {viewReport &&
                    <>
                        <div className="overlay" onClick={closeWindow}></div>
                        <div className="modalPopup modalPopupw">
                            <h4>Payment Approval Report</h4>
                            <table className="data-grid-table">
                                <tbody>
                                    <tr>
                                        <td className="text-right">Net Amount: </td>
                                        <td className="text-right">{reportData?.netAmount}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}><b className="color444"> Amount after excluding bills</b></td>
                                    </tr>
                                    {getExcludingData(reportData?.amountCalculation)}
                                    <tr>
                                        <td className="text-right">Amount Calculated after excluding above bills</td>
                                        <td className="text-right">{reportData?.amountCalculated}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">Percentage of referrer on calculated amount</td>
                                        <td className="text-right">{reportData?.percentageOfReferrer}%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right"><b>Total</b></td>
                                        <td className="text-right"><b>{reportData?.referrerAmount}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                <div
                    className="layoutContent">
                    <div className='conflicts_tabs'>
                        <ul>
                            {tabsName.map((d, i) => <li className={`${aciveTab === i ? 'activeTab' : ''}`} onClick={() => handleTabClick(d, i)}>{d}</li>)}
                        </ul>
                    </div>
                    { aciveTab === 0  ?  
                    <>
                    <div className="mt10 mb10">
                        <Grid container className="mt2" spacing={2}>
                            <Grid item xs={5} className="text-right">
                                <Item style={{ paddingTop: '1.1rem' }}>Select Date</Item>
                            </Grid>
                            <Grid item xs={7} className="text-left">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Choose a Date"
                                        value={adminDate}
                                        disableFuture
                                        onChange={async (newValue) => {
                                            setAdminDate(newValue);
                                            fetchAllData(newValue)
                                        }}
                                        renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                    </div>
                    <div>
                        <table className="data-grid-table">
                            <thead>
                                <tr>
                                    {tableHead.map(d => <th>{d}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {docArray.length > 0 ?
                                    docArray.map((d, i) => (
                                        <tr>
                                            <td>{d.MR_NO}</td>
                                            <td>{d.referrerName}</td>
                                            <td>{d.referrerMobileNumber}</td>
                                            <td>{d.patientName}</td>
                                            <td>{setDateFormat(d.dateOfDischarge)}</td>
                                            <td className="text-center">{d.referrerAmount}</td>
                                            <td>
                                                {d.modified ?
                                                    <div className="modfiedTextAmt">
                                                        <span className="diff">{d.amountModified}
                                                            <InfoIcon className="iconInfo" />
                                                            <span className="descToolTip">{d.description}</span>
                                                        </span>
                                                    </div>
                                                    :
                                                    (
                                                        d.editField ?
                                                            <div className="editAmount">
                                                                <div>
                                                                    <input type="text" placeholder="Amount" value={amountModified} onChange={handleInputAmt} />
                                                                    <textarea placeholder="description" value={description} onChange={handleInputDesc}></textarea>
                                                                </div>
                                                                <div className="editAmountBtn">
                                                                    <button onClick={() => submitModifiedAmt(d, i)}><CheckIcon /></button>
                                                                    <button onClick={() => cancelModify(i)}><CloseIcon /></button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <button className={`modifyAmtBtn ${d.approvedBy ? 'disableAprove' : ''}`} onClick={() => handleModify(d, i)}>Modify Amount</button>
                                                    )}
                                            </td>
                                            <td onClick={() => handleViewReport(d, i)} className="text-center viewBtnModal">View </td>
                                            <td>
                                                {d.paymentAcceptanceStatus === 'modified' && <span>Modified</span>}
                                                {d.paymentAcceptanceStatus === 'approved' && <span>Approved</span>}
                                                {d.paymentAcceptanceStatus === '' &&
                                                    <button onClick={() => handleApprove(d, i)} className={`tableApproveBtn ${d.modified ? 'disableAprove' : ''}`}>Approve</button>}</td>
                                        </tr>
                                    )) :
                                    <tr>
                                        <td className="text-center" colSpan={9}>No Data for the selected Date</td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                        <div className="flex-center">
                            {docArray.length > 0 && docArray.filter(d => d.paymentAcceptanceStatus === 'approved').length > 0 &&
                                <Button className="gradientBtn" onClick={handleGeneratePayment}>Generate Payment File</Button>
                            }
                        </div>
                    </div>
                    </>
                    :''}

                    {aciveTab === 1 && <DisputePaymentApproval />}
                </div>
            </div>
        </>
    );
}

export default PaymentApproval