import { Button, Snackbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef, gridDateFormatter, GridSelectionModel } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import Sidemenu from '../components/Sidemenu';
import HttpService from "../services/HttpService";
import './pages.css';
import {ReactComponent as UserImg} from './../assets/images/user.svg';
import moment from "moment";
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA } from "../utils/constants";

const columns: GridColDef[] = [
    { field: 'mobileNumber', headerName: 'Mobile', flex:1, headerClassName:'aaa'},
    { field: 'name', headerName: 'Patient Name', flex:1 },
    { field:'specialityOfTreatment', headerName:'Speciality Of Treatment', flex:1},
    { field:'referralName', headerName:'Referral Name', flex:1}
];


const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
      backgroundImage: GRADIENTIMAGECOLOR,
    }
}));

const _axios = HttpService.getAxiosClient();

const DuplicateRecords = () => {
    const classes = useStyles();
    let selectedRows: GridSelectionModel;
    let selectedRows1: GridSelectionModel;
    
    const [rows, setRows] = React.useState<any[]>([]);
    const [rows1, setRows1] = React.useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [gotData, setGotData] = React.useState(false);
    const [gotData1, setGotData1] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [selectIndex, setSelectIndex] = React.useState("");
    const [selectedId, setSelectedId] = React.useState("");
    const [businessKey, setBusinessKey] = React.useState<String|undefined>("");
    const [processInstanceId, setProcessInstanceId] = React.useState<String|undefined>("");

    useEffect(() => {
        let currentUrlParams = window.location.search.split('?',2); 
        let phNumber = currentUrlParams[1].split('&', 1).pop()?.split('=').pop();
        let kimsId = currentUrlParams[1].split('&', 2).pop()?.split('=').pop();
        let kdate = currentUrlParams[1].split('&', 3).pop()?.split('=').pop();
        let businessKey = currentUrlParams[1].split('&', 4).pop()?.split('=').pop();
        let processInstanceId = currentUrlParams[1].split('&', 5).pop()?.split('=').pop();
        setBusinessKey(businessKey);
        setProcessInstanceId(processInstanceId);
        getData(phNumber);
        getDischaredData(kimsId, kdate);
    },[]);

    const getData = async(id:String|undefined) => {
        let requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?formId=${FORMID.instaReferalPatientId}&filter=formData.mobileNumber:${id};formData.isTurnedUp:false`;
        let res = await _axios.get(requestURL);
        let tableData = res.data.data.filter((d:any)=>d.formData.isTurnedUp === false);
        if(tableData.length === 0){
            setGotData(false)
        }
        else{
            if(res && res.data.success) {
                if(res?.data?.data?.length > 0) {
                  setRows(data => res.data.data.map((d:any) => ({id:d.id, ...d.formData})));
                  setGotData(true)
                }
            }
        }        
    }

    const getDischaredData = async(kimsId:String|undefined, kdate:String|undefined) => {
        let requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}insta/v1/discharged-patients?fromDate=${kdate}T00:00:00Z&mrNumber=${kimsId}&toDate=${kdate}T23:59:59Z`;
        let res = await _axios.get(requestURL);
        if(res && res.data.success) {
            if(res?.data?.data?.length > 0) {
            res.data.data.forEach((d:any,i:Number)=>d.id = i)
              setRows1(data => [...res.data.data]);
              setGotData1(true)
            }
        }
    }

    const transactionUpdateHandler = async() => {
        var selectedObj = rows.filter(d => d.id === selectedId);
        let requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}workflow/v1/process/resume`;
        let id = selectedObj[0].id;
        delete selectedObj[0].id
        const payload = {
            "processInstanceId" :processInstanceId,
            "message": "recordUpdated",
            "businessKey": businessKey,
            "variables": {
                "patientRecord": {
                    'id': id,
                    'formData': selectedObj[0],                     
                },
                "duplicate":"true"
            }
        }
        try{
            let res = await _axios.post(requestURL, payload);
            console.log(res)
            setMessage('Successfully Merged Data');
            setOpen(true);
            getData(selectedObj[0].mobileNumber);
        }
        catch(e:any){
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }        
    }

    const handleSingleSelect = (data:any) => {
        if(data.length === 0){
            setSelectIndex("");
        }
        else
        {
            setSelectIndex(data[0])        
            selectedRows=data[0];
            setSelectedId(data[0])
        }
    }

    const setDateFormat = (date:String) => {
        let dateWithOutString = date.replace(/"/gi, "");
        dateWithOutString = moment(dateWithOutString).format('DD-MMM-YYYY / hh:mm')
        return dateWithOutString;
    }
    

    return(
        <>
            <Sidemenu />

            <div style={{paddingLeft: '6.2em', fontSize: '1.3rem', backgroundColor: '#F7F8FA', color: '#717982'}}>
                <p style={{marginTop: '0px', paddingTop: '1.2em'}}>Duplicate Records</p>

                <Snackbar
                    ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                    }}
                    open={open}
                    autoHideDuration={3000}
                    message={message}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                />
                <div
                    style={{
                    width: '86vw',
                    backgroundColor: "white",
                    padding: '60px',
                    boxSizing:'border-box'
                    }}>

                    <div>
                        {rows1.map((d,i) => (
                            <div className="patientCard" key={i}>
                                <div className="profile1">
                                    <div className="profileCard">
                                        <UserImg />
                                        <div className="profileDetail">
                                            <h4>{d.PATIENT_NAME}</h4>
                                            <p className="mob">{d.PATIENT_PHONE}</p>
                                        </div>
                                    </div>
                                    <div> <span>Gender</span> <p>{d.PATIENT_GENDER}</p> </div>
                                    <div> <span>Center Name</span> <p>{d.CENTER_NAME}</p></div>
                                    <div> <span>MR Number</span><p>{d.MR_NO}</p></div>
                                </div>

                                <div className="profile1">
                                    <div> <span>Doctor Name</span> <p>{d.DOCTOR_NAME}</p></div>
                                    <div> <span>Registerd Date & Time</span> <p>
                                        {/* {d.REG_DATE_TIME} */}
                                        {setDateFormat(d.REG_DATE_TIME)}
                                    </p></div>
                                    <div> <span>Discharge Date & Time</span> <p>
                                        {/* {d.DISCHARGE_DATE_TIME} */}
                                        {setDateFormat(d.DISCHARGE_DATE_TIME)}
                                    </p></div>
                                    <div> <span>OP Type</span> <p>{d.OP_TYPE}</p></div>
                                    <div> <span>Rate Plan ID</span> <p>{d.RATE_PLAN_ID}</p></div>
                                </div>

                                <div className="profile1">
                                    <div> <span>Visit ID</span> <p>{d.VISIT_ID}</p></div>
                                    <div> <span>Referred by</span> <p>{d.REFERRED_BY ? d.REFERRED_BY : '-'}</p></div>
                                    <div> <span>Referred ID</span> <p>{d.REFERRER_ID ? d.REFERRER_ID : '-'}</p></div>
                                    <div> <span>Ward ID</span> <p>{d.WARD_ID}</p></div>
                                    <div> <span>Ward Name</span> <p>{d.WARD_NAME}</p></div>
                                </div>
                            </div>
                        ))}
                    </div>

                    
                    <div className="profileDataGrid">
                        {gotData ? 
                        <>
                          <div style={{ height: '400px', width: '100%' }}>                           
                            <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={15}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            isRowSelectable={(params: any) =>{
                                if(selectIndex !== ""){
                                    return params.row.id === selectIndex;
                                }
                                else{
                                    return true;
                                }                                
                            }}
                            onSelectionModelChange={(data) => handleSingleSelect(data)}
                            
                            />
                         
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button onClick={transactionUpdateHandler} style={{
                                borderRadius: 15,
                                marginTop: '3vh',
                                backgroundImage: GRADIENTIMAGECOLOR,
                                width: '100px',
                                color: '#FFFFFF'
                            }}> Save </Button>
                        </div>
                        </>
                           :
                           <div style={{textAlign:'center', padding:'40px',}}>
                               Duplicate Data Already Processed...
                           </div>
                           }
                    </div>
                    </div>
                </div>
            
        </>
    )
}

export default DuplicateRecords;