import HttpService from "./HttpService";

const mdMobile1 = '918688552175';

const mdMobile = localStorage.getItem('MDMobile');

export const smsService = async (userType:string, msg:string) => {
    const _axios = HttpService.getAxiosClient();
    const payload = 
    { 
        // userId:userType === 'managingDirector' ? mdMobile : mdMobile1, 
        userId:'8688552175',
        message:msg,
        type:'sms'
    }
    let resp = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}insta/v1/notifications`, payload);
}