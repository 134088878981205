import Keycloak from "keycloak-js";
import axios from 'axios';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();


const keycloak = new Keycloak({
  url: process.env.REACT_APP_AUTH_SERVER_URL || "https://keycloak-tsplatform.techsophy.com/auth",
  realm: process.env.REACT_APP_REALM || "techsophy-platform",
  clientId: process.env.REACT_APP_CLIENT_ID || "tp-ui-core"
});

const initKeycloak = (onAuthenticatedCallback: any) => {
  keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (authenticated) {
        sessionStorage.setItem('react-token', keycloak.token || 'no');
        // postLoginSteps(getUsername(), getUserEmail());
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    });
};

const clearAllLocalstorage = () => {
  localStorage.removeItem("role");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("userRole");
  localStorage.removeItem("userName");
  localStorage.removeItem("userId");

};

const doLogin = keycloak.login;

const doLogout = () => {
  clearAllLocalstorage();
  history.push('/')
  keycloak.logout();
};

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback?: any) =>
  keycloak.updateToken(5).then(successCallback).catch(doLogin);

keycloak.onTokenExpired = () => {
  updateToken().then(() => {
    if(keycloak?.token) {
        sessionStorage.setItem('react-token', keycloak.token);
    }
  });
};

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const getUserEmail = () => keycloak.tokenParsed?.email;

const getSub = () => keycloak.tokenParsed?.sub

const hasRole = (roles: string[]) => roles.some((role: string) => keycloak.hasRealmRole(role));

const fetchTokenDifferently = async () => {
    const params = new URLSearchParams();
    params.append('client_id', 'tp-ui-core');
    params.append('username', 'tejaswini');
    params.append('password', 'test');
    params.append('grant_type', 'password');
    const tokenData = await axios.post(`https://keycloak-tsplatform.techsophy.com/auth/realms/techsophy-platform/protocol/openid-connect/token`, params);
    return tokenData.data.access_token;
};

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUserEmail,
  hasRole,
  fetchTokenDifferently,
  getSub,
};

export default KeycloakService;
