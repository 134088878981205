import React, { useState } from 'react';
import HttpService from '../services/HttpService';
import { makeStyles } from '@mui/styles';
import { Snackbar } from "@mui/material";
import moment from 'moment';
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA } from '../utils/constants';

const _axios = HttpService.getAxiosClient();

const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
        backgroundImage: GRADIENTIMAGECOLOR,
    }
}));
let newDate = new Date();

interface Comment {
    comment: string
    timeStamp: string
  }

type CommentsData = {
    comments:Comment[];
    mrNumber:string;
    patientName:string;
    mobileNumber:string;
}

const TransactionStatus = (props: any) => {
    const classes = useStyles();

    const [modalPop, setModalPop] = useState(false);
    const [modalPop1, setModalPop1] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [commentsFormData, setCommentsFormData] = React.useState<CommentsData | null>(null);
    const [commentId, setCommentId] = React.useState('');

    const closeWindow = () => {
        setModalPop(false)
        setModalPop1(false)
    }

    const handleSuccessStatus = async () => {
        try {
            let selectedObj = props.data[selectedIndex];
            let commId = await getComments(selectedObj.MR_NO);
            const payload = {
                id: selectedObj.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    transactionStatus: 'success',
                    transactionProcessedDate:moment(newDate).format('YYYY-MM-DD HH:mm:ss'),
                    ...(props.approvedBy === 'dispute' && {disputeTransactionStatus:'success'})
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            setMessage('Payment status updated as "Transation Success"!');
            if(resp){
                if(props.approvedBy === 'manager' || props.approvedBy === 'managingDirector')
                {
                    let message = `Greeting from kims team, Dear ${selectedObj.referrerName}, INR ${selectedObj.paymentAcceptanceStatus === 'approved' && selectedObj.paymentAcceptanceStatusByreferrer === '' && selectedObj.finalisedAmount} , credited to A/C no:${selectedObj.accountNumber} for referring a patient: ${selectedObj.patientName}.`
                    setTimeout(() => {
                        updateComments(message, selectedObj.referrerID, commId)
                    },1000)                    
                }
                else{
                    let message = `Greeting from kims team, Dear ${selectedObj.referrerName}, INR ${selectedObj.disputePaymentAcceptanceStatus === 'approved' && selectedObj.paymentAcceptanceStatusByreferrer === 'rejected' && selectedObj.disputeFinalisedAmount} , credited to A/C no:${selectedObj.accountNumber} for referring a patient: ${selectedObj.patientName}.`
                    setTimeout(() => {
                        updateComments(message, selectedObj.referrerID, commId)
                    },1000)                    
                }
            }
            setOpen(true);
            props.callApi();
            closeWindow();
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
            closeWindow();
        }
    }

    const getComments = async(MRNO:string) => {
        try{
            let dataa = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?filter=formData.mrNumber:"${MRNO}"&formId=${FORMID.commentsId}`);
            if(dataa.data.data.length > 0){
              setCommentsFormData(dataa.data.data[0]?.formData);
              setCommentId(dataa.data.data[0].id);
              return dataa.data.data[0].id;
            }
          }
          catch (e:any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const updateComments = async(message:string, refId:string, id:string) => {
        try{
          const commentsData ={...commentsFormData};
          const oldComments:Comment[]= commentsData.comments??[];
          const newComment:Comment={
            timeStamp:new Date().toISOString(),
            comment:message
          }
          oldComments?.push(newComment)
          const bodyObj = {
            formId: FORMID.commentsId,
            id:id,
            formData:{
              // mrNumber:commentsData.mrNumber,
              // patientName: commentsData.patientName,
              // mobileNumber: commentsData.mobileNumber,
              comments:oldComments,
            }
          }
          let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, bodyObj);
          if(resp)
          {
            callNotification(message, refId);
          }      
        }
        catch (e:any) {
          console.log(e);
          setMessage(e.message);
          setOpen(true);
        }
      }

    const callNotification = async(message:string, id:string) => {
        try{
          let payload = {
            userId:id,
            message:message,
            type:'fireBase'
          }
          let resp = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}insta/v1/notifications`, payload);
        }
        catch (e:any) {
          console.log(e);
          setMessage(e.message);
          setOpen(true);
        }
    }
    
    
    const handleFailureStatus = async () => {
        try {
            let selectedObj = props.data[selectedIndex];
            const payload = {
                id: selectedObj.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    transactionStatus: 'failure',
                    transactionProcessedDate:moment(newDate).format('YYYY-MM-DD HH:mm:ss')
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            setMessage('Payment status updated as "Transation Failed"!');
            setOpen(true);
            props.callApi();
            closeWindow();
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
            closeWindow();
        }
    }
    const handleSuccess = (d: any, i: any) => {
        setModalPop(true);
        setSelectedIndex(i)
    }
    const handleFailure = (d: any, i: any) => {
        setModalPop1(true);
        setSelectedIndex(i)
    }
    return (
        <div>

            <Snackbar
                ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                }}
                open={open}
                autoHideDuration={3000}
                message={message}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />


            {modalPop && (
                <>
                    <div className="overlay" onClick={closeWindow}></div>
                    <div className="modalPopup">
                        <h4>Are you sure you want to update Payment status as Success</h4>
                        <button className="yesRemoveBtn" onClick={handleSuccessStatus}>Yes, Update</button>
                        <button className="cancelBtn" onClick={closeWindow}>close</button>
                    </div>
                </>
            )}

            {modalPop1 && (
                <>
                    <div className="overlay" onClick={closeWindow}></div>
                    <div className="modalPopup">
                        <h4>Are you sure you want to update Payment status as Failure</h4>
                        <button className="yesRemoveBtn" onClick={handleFailureStatus}>Yes, Update</button>
                        <button className="cancelBtn" onClick={closeWindow}>close</button>
                    </div>
                </>
            )}
            {props.data.length > 0 ?
                <table className="data-grid-table">
                    <thead>
                        <tr>
                            <th>MR No</th>
                            <th>Patient Name</th>
                            <th>Referrer Name</th>
                            <th>Referrer Mobile</th>
                            <th>Referrer AC.NO</th>
                            <th>IFSC Code</th>
                            <th>{props.approvedBy==='dispute' ? 'Dispute Finalised Amount' : 'Finalised Amount'}</th>
                            <th>Approved By</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((d: any, i: number) => (
                            <tr key={i}>
                                <td>{d.MR_NO}</td>
                                <td>{d.patientName}</td>
                                <td>{d.referrerName}</td>
                                <td>{d.referrerMobileNumber}</td>
                                <td>{d.accountNumber}</td>
                                <td>{d.ifscCode}</td>
                                <td>{props.approvedBy==='dispute' ? d.disputeFinalisedAmount : d.finalisedAmount}</td>
                                <td>{d.approvedBy}</td>
                                <td>

                                {/* {props.approvedBy ==='manager' || props.approvedBy ==='managingDirector' && (
                                    <> */}
                                   
                                    {/* </>
                                )} */}

                                {props.approvedBy==='dispute' ? (
                                    <>
                                    {(d.disputeTransactionStatus === "" || d.disputeTransactionStatus === undefined || d.disputeTransactionStatus === null) &&  
                                    <>
                                        <button className='btnHandle' onClick={() => handleSuccess(d, i)}>Success</button>
                                        {/* <button className='btnHandle failurBt' onClick={() => handleFailure(d, i)}>Failure</button> */}
                                    </>
                                    }
                                     {d.disputeTransactionStatus === 'success' && <span className='colorGreen'>Approved</span>}                                    
                                    {d.disputeTransactionStatus === 'failure' && <span className='colorRed'>Failed</span>}
                                    </>
                                )
                                :
                                <>
                                 {(d.transactionStatus === "" || d.transactionStatus === undefined || d.transactionStatus === null) &&  
                                <>
                                    <button className='btnHandle' onClick={() => handleSuccess(d, i)}>Success</button>
                                    {/* <button className='btnHandle failurBt' onClick={() => handleFailure(d, i)}>Failure</button> */}
                                </>
                                }
                                {d.transactionStatus === 'success' && <span className='colorGreen'>Approved</span>}                                    
                                {d.transactionStatus === 'failure' && <span className='colorRed'>Failed</span>}
                                </>
                            }
                                
                               
                                    
                                   
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                : null}
        </div>
    );
}

export default TransactionStatus;