import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import FilterIcon from "../assets/images/filter.svg";
import "../pages/conflicts/conflicts.css";
import { Box } from "@mui/material";

export default function PositionedMenu(props: any) {
  return (
    <>
      <Box
        ref={props.anchorRef}
        className="filter_block"
        onClick={()=>props.handleToggle()}
      >
        <img className="filterIcon" src={FilterIcon} />
        <label className="filter_label">Filter</label>
      </Box>
      <Popper
        open={props.open}
        anchorEl={props.anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  // onKeyDown={()=>props.handleListKeyDown()}
                  sx={{ display: "flex" }}
                >
                  <MenuItem>{props.menuItems}</MenuItem>
                </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
