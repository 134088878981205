export const specialCharValidation = (input: string) => {
    const regex = /^[a-zA-Z \b]+$/;
    return (!input || !regex.test(input) === false);
}

export const emailValidation = (email: string) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
}

export const VERSION = 'WORKING'