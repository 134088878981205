import React, { ReactElement, FC, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import KeycloakService from "../services/KeycloakService";
import { Snackbar, Typography, Grid, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Sidemenu from '../components/Sidemenu';
import { useParams } from "react-router-dom";
import HttpService from "../services/HttpService";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { DataGrid, GridColDef, GridValueGetterParams, GridSelectionModel } from '@mui/x-data-grid';
import TransactionStatus from "./transactionStatus";
import { GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA, PAYMENTADVISETABLEHEADDATA } from "../utils/constants";

const _axios = HttpService.getAxiosClient();
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));

const useStyles = makeStyles(() => ({
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  }
}));

const columns: GridColDef[] = PAYMENTADVISETABLEHEADDATA;

const PaymentAdvise: FC<any> = (): ReactElement => {

  const classes = useStyles();

  const { docId } = useParams();
  const userType = localStorage.getItem('userRole');
  let selectedRows: GridSelectionModel;

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  // const [userType, setUserType] = React.useState('');
  const [transactionStatus, setTransactionStatus] = React.useState('');
  const [downloadData, setdownloadData] = React.useState(['']);
  const [rows, setRows] = React.useState<any[]>([]);
  const [tableRows, setTableRows] = React.useState([{}]);
  const [transactionDate, setTransactionDate] = React.useState<Date | null>(null);
  const [adminDate, setAdminDate] = React.useState<Date | null>(null);
  const [approvedBy, setApproveBy] = React.useState('');
  const [dataId, setDataId] = React.useState('');
  const [transactionsData, setTransactionsData] = React.useState<any>([]);

  useEffect(() => {
    // const checkKeycloakActive = async () => {
    //   const userData = await getUserData();
    //   if(userData?.data?.data.length > 0) {
    //     setUserType(userData?.data?.data[0]?.userData.department);
    //     localStorage.setItem('userRole',userData?.data?.data[0]?.userData.userType)
    //   }
    // }
    // checkKeycloakActive();
  }, []);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setTransactionStatus(event.target.value as string);
  };

  // const getUserData = async () => {
  //   return _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}accounts/v1/users?filter-column=userName&filter-value=${KeycloakService.getUsername()}`).catch((error) => {
  //     console.log('rrrrr', error);
  //   });
  // };

  const handleDownload = async (docId: string, req?: boolean) => {
    const idExists = docId?.length;
    if (idExists && idExists > 8) {
      const requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}dms/v1/documents/download?id=${docId}`;
      const download = await _axios.get(requestURL);
      // if (req) window.open(download.config.url);
      const transactionArr = download.data.split(/\r?\n/);
      setdownloadData((arr) => [...transactionArr]);
      setdownloadData((arr) => [...arr.filter(item => item !== '')]);
      const filtered = transactionArr.filter((item: string) => item !== '').join('\n');
      downloadFile(filtered, `${approvedBy}_payment_list_${converDate(adminDate)}.txt`);
      if (!req) {
        for (let [index, value] of filtered.entries()) {
          const wordArray = value.split(',');
          const input = {
            type: wordArray[0], hospitalAccNo: wordArray[1], amount: wordArray[2], currency: wordArray[3],
            referrerAccNo: wordArray[4], ifsc: wordArray[5], name: wordArray[6], id: index
          }
          setRows((row) => [...row, input]);
        }
      }
    }
  };

  const downloadFile = (text:string, filename:string) => {
    var blob = new Blob([text], {type: "text/plain"});
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  }

  const transactionUpdateHandler = async () => {
    let descriptionString = ''
    if (transactionStatus === 'partiallyfailed' && selectedRows?.length > 0) {
      selectedRows.forEach((id) => {
        descriptionString = descriptionString + ' : ' + downloadData[Number(id)];
      });
    }
    const transaction_date = moment(transactionStatus ? transactionDate : new Date(), "MM-DD-YYYY").format("MM-DD-YYYY");
    let finalTrasactionDate = '';
    if (transaction_date.slice(3, 4) === '0') {
      finalTrasactionDate = transaction_date.slice(0, 3) + transaction_date.slice(4, transaction_date.length);
      if (finalTrasactionDate.slice(0, 1) === '0') {
        finalTrasactionDate = finalTrasactionDate.slice(1, finalTrasactionDate.length);
      }
    } else if (transaction_date.slice(0, 1) === '0') {
      finalTrasactionDate = transaction_date.slice(1, transaction_date.length);
    }
    else finalTrasactionDate = transaction_date;
    const updateDataPayload = {
      "processDefinitionKey": "Process_pa9rta5",
      "businessKey": "rfmTransaction",
      "variables": {
        "transactionDate": finalTrasactionDate,
        "transactionStatus": transactionStatus,
        "description": descriptionString
      }
    }
    const processUpdate = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}workflow/v1/process/start`,
      updateDataPayload).catch(error => {
        console.log('error', error);
        setMessage('Sorry some error while processing transaction update, please try again');
        setOpen(true);
      });
    if (processUpdate?.data?.success) {
      setdownloadData((arr) => [...arr.filter(item => item === '')]);
      setTransactionStatus('');
      setTransactionDate(null);
      setMessage('Successfully updated transaction');
      setOpen(true);
    }
  }

  const handleSelectChangeApprovedBy = (event: SelectChangeEvent) => {
    setApproveBy(event.target.value as string);
    setTransactionsData([]);
  }

  const converDate = (str: any) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleApiCallAgain = () => {
    setTimeout(() => {
      updateTransacationStatus();
    }, 1500)
  }
  const updateTransacationStatus = async () => {
    if (!adminDate) {
      setMessage('Please Select a date to proceed.');
      setOpen(true);
      return false
    }
    if (approvedBy.length === 0) {
      setMessage('Please Select Approved by.');
      setOpen(true);
      return false
    }
    else {
      try {
        if (approvedBy === 'dispute') {
          const requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?page=0&size=10000&formId=1030055364952023040&filter=formData.disputeFileGenerationStatus:true;formData.disputePaymentAcceptanceStatus:"approved";formData.paymentAcceptanceStatusByreferrer:"rejected"`;
          let res = await _axios.get(requestURL);
          setTransactionsData(res.data.data.content.map((d: any) => ({ id: d.id, ...d.formData })))
        }
        if (approvedBy === 'manager') {
          const requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?page=0&size=10000&formId=1030055364952023040&filter=formData.dateOfDischarge:${converDate(adminDate)};formData.fileGenerationStatus:true;formData.approvedBy:${approvedBy}`;
          let res = await _axios.get(requestURL);
          setTransactionsData(res.data.data.content.map((d: any) => ({ id: d.id, ...d.formData })))
        }
        if (approvedBy === 'managingDirector') {
          const requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?page=0&size=10000&formId=1030055364952023040&filter=formData.fileGenerationStatus:true;formData.approvedBy:${approvedBy};formData.modified:true`;
          let res = await _axios.get(requestURL);
          setTransactionsData(res.data.data.content.map((d: any) => ({ id: d.id, ...d.formData })))
        }
      }
      catch (e: any) {
        console.log(e);
        setMessage(e.message);
        setOpen(true);
      }
    }
  }

  const getDataForFinance = async () => {
    if (!adminDate) {
      setMessage('Please Select a date to proceed.');
      setOpen(true);
      return false
    }
    if (approvedBy.length === 0) {
      setMessage('Please Select Approved by.');
      setOpen(true);
      return false
    }
    else {
      try {
        const requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}dms/v1/documents?include-sub-folders=true&filter=fileName:"${approvedBy}_payment_list_${converDate(adminDate)}.txt";documentName:${approvedBy}`;
        let res = await _axios.get(requestURL);
        console.log(res);
        if (res.data.data.content.length > 0) {
          setDataId(res.data.data.content[0].id);
          handleDownload(res.data.data.content[0].id, true);
        }
        else {
          setMessage('Sorry data not available for the date');
          setOpen(true);
        }
      }
      catch (e: any) {
        console.log(e);
        setMessage(e.message);
        setOpen(true);
      }
    }
  }


  const getDataForFinance1 = async () => {
    const download_date = moment(new Date(Date.now() - 864e5), "YYYY-MM-DD").format('YYYY-MM-DD');
    let admin_date = moment(adminDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let finalDate = '';
    adminDate ?
      finalDate = admin_date : finalDate = download_date
    if (finalDate.slice(5, 6) === '0') {
      finalDate = finalDate.slice(0, 5) + finalDate.slice(6, finalDate.length);
    }
    if (finalDate.slice(7, 8) === '0') {
      finalDate = finalDate.slice(0, 7) + finalDate.slice(8, finalDate.length);
    }
    const financeData = await dateFilterData(finalDate);
    if (financeData && financeData.data.success) {
      if (financeData.data.data.content.length >= 1) {
        const documentId = financeData.data.data.content[0].id;
        handleDownload(documentId, true);
      } else {
        setTransactionStatus('');
        setTransactionDate(null);
        setMessage('Sorry data not available for the date');
        setOpen(true);
      }
    }
  }

  const dateFilterData = (filterDate: string) => {
    const requestURL = `${process.env.REACT_APP_MAIN_SERVER_URL}dms/v1/documents?include-sub-folders=true&filter=fileName:"payment_list_${filterDate}.txt"`;
    return _axios.get(requestURL);
  }

  return (
    <>
      <Sidemenu />
      <div className="layoutParent">
        <p className="layoutParentP">Payment Advise</p>
        <Snackbar
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarStyleViaContentProps
          }}
          open={open}
          autoHideDuration={3000}
          message={message}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
        <div
          className="layoutContent p40">
          <div className="paymentAdvGrid">
            <Grid container className="mt2" spacing={2}>
              <Grid item xs={5} className="text-right">
                <Item style={{ paddingTop: '1.1rem' }}>Select Date</Item>
              </Grid>
              <Grid item xs={7} className="text-left">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Choose a Date"
                    value={adminDate}
                    disableFuture
                    onChange={async (newValue) => {
                      setAdminDate(newValue);
                    }}
                    renderInput={(params) => <TextField sx={{ m: 1, width: 200 }} size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container spacing={2} className="mt2">
              <Grid item xs={5} className="text-right">
                <Item style={{ paddingTop: '1.1rem' }}>Approved By</Item>
              </Grid>
              <Grid item xs={7} className="text-left">
                <FormControl sx={{ m: 1, minWidth: 200, marginLeft: 0 }} size="small">
                  <InputLabel id="demo-select-small">Approved By</InputLabel>
                  <Select
                    value={approvedBy}
                    label="Approved By"
                    onChange={handleSelectChangeApprovedBy}
                  >
                    <MenuItem value={'manager'}>Manager</MenuItem>
                    <MenuItem value={'managingDirector'}>Managing Director</MenuItem>
                    <MenuItem value={'dispute'}>Dispute</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button className="gradientBtn" onClick={() => {
              getDataForFinance();
            }} > DOWNLOAD PAYMENT ADVISE </Button>
            <Typography sx={{ fontSize: 12, marginTop: '0.35em', marginBottom: '10px' }} color="text.secondary" gutterBottom>
              Please click this button to download the payments you require
            </Typography>

            <Button className="gradientBtn" onClick={() => {
              updateTransacationStatus();
            }}> UPDATE TRANSACTION STATUS </Button>

          </div>
          <div className="mt10">
            <TransactionStatus approvedBy={approvedBy} data={transactionsData} callApi={handleApiCallAgain} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentAdvise;
