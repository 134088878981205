import React, { useEffect, useState } from "react";
import HttpService from '../services/HttpService';
import { makeStyles } from '@mui/styles';
import { Button, Snackbar } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA } from "../utils/constants";
import { reportDataObj , docsData} from "../types/Module_Type";

const _axios = HttpService.getAxiosClient();

const tableHead = ['MR_No', 'Patient Name', 'Referrer Name', 'Referrer Mobile', 'Amount Paid', 'Amount in Dispute ', 'Amount OutStanding', 'Action'];

const userId = localStorage.getItem('userId')
const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
        backgroundImage: GRADIENTIMAGECOLOR,
    }
}));





interface Comment {
    comment: string
    timeStamp: string
}
type CommentsData = {
    comments: Comment[];
    mrNumber: string;
    patientName: string;
    mobileNumber: string;
}

const DisputePaymentApprovalMD = () => {
    const classes = useStyles();

    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [docArray, setDocArray] = useState<docsData[]>([]);
    const [viewReport, setViewReport] = useState(false);
    const [reportData, setReportData] = useState<reportDataObj | null>(null);
    const [finaliseAmount, setFinaliseAmount] = useState('');
    const [finAmtDesc, setFinAmtDesc] = useState('');
    const [commentsFormData, setCommentsFormData] = React.useState<CommentsData | null>(null);
    const [commentId, setCommentId] = React.useState('');

    useEffect(() => {
        fetchAllDisputeRecords();
    }, [])

    const fetchAllDisputeRecords = async () => {
        try {
            let res = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?page=0&size=10000&formId=${FORMID.paymentAcceptanceTranscationId}&filter=formData.paymentAcceptanceStatusByreferrer:"rejected";formData.disputeAcceptanceStatusByManager:"approved";formData.disputeFileGenerationStatus:false`);
            if (res && res.data.success) {
                if (res?.data?.data?.content?.length > 0) {
                    // setRows(data => res.data.data.map((d: any) => ({ id: d.id, ...d.formData })));
                    setDocArray(res.data.data.content.map((d: any) => ({ id: d.id, ...d.formData })));
                }
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e?.message);
            setOpen(true);
        }
    }

    const closeWindow = () => {
        setViewReport(false);
    }

    const getExcludingData = (excludingData: any) => {
        let newArray = Object.keys(excludingData).map(name => ({ name, value: excludingData[name] }))
        return (
            newArray.map(arObj => <tr><td className="text-right">{arObj.name} :</td><td className="text-right">{arObj.value}</td></tr>)
        )
    }

    const calculateVal = (da: number, fa: number) => {
        return da - fa;
    }

    const approveAmount = async (data: any, index: number) => {
        try {
            const payload = {
                id: data.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    disputeAcceptanceStatusByManager: 'approved'
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            if (resp.data.success) {
                setTimeout(() => {
                    setMessage('Referrer Amount Approved.');
                    setOpen(true);
                    fetchAllDisputeRecords();
                }, 1500)

            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }
    const rejectAmount = async (data: any, index: number) => {
        try {
            const payload = {
                id: data.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    disputeAcceptanceStatusByManager: 'rejected',
                    disputeAcceptanceStatusByMd: 'rejected',
                    disputePaymentAcceptanceStatus: "rejected"
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            if (resp.data.success) {
                setTimeout(() => {
                    setMessage('Referrer Amount Rejected.');
                    setOpen(true);
                    fetchAllDisputeRecords();
                }, 1500)

            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const handleViewReport = (d: any, i: any) => {
        setViewReport(true);
        setReportData(d ?? {});
        // setFinaliseAmount(d.disputedAmount)
    }

    const handleFinaliseAmount = (e: any) => {
        setFinaliseAmount(e.target.value);
    }
    const handleDescription = (e: any) => {
        setFinAmtDesc(e.target.value);
    }

    const handleApprove = async (data: any, index: any) => {
        try {
            getCommenets(data);
            const payload = {
                id: data.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    disputeAcceptanceStatusByMd: 'approved',
                    approvedBy: localStorage.getItem('userRole'),
                    disputeFinalisedAmount: finaliseAmount,
                    finalisedDescription: finAmtDesc,
                    disputePaymentAcceptanceStatus: "approved"
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            if(resp.data.success){
            setTimeout(() => {
                setFinaliseAmount('');
                setFinAmtDesc('');
                setMessage('Referrer Amount Approved.');
                setOpen(true);
                fetchAllDisputeRecords();
                setViewReport(false);
            }, 1500)
            }         
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const getCommenets = async (data: any) => {
        try {
            let dataa = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?filter=formData.mrNumber:"${data.MR_NO}"&formId=${FORMID.commentsId}`);
            if (dataa.data.data.length > 0) {
                //   setCommentsFormData(dataa.data.data[0]?.formData);
                //   setCommentId(dataa.data.data[0].id)
                const commentsData = dataa.data.data[0]?.formData;
                const oldComments: Comment[] = commentsData.comments ?? [];
                const newMsg = `Dear ${data.referrerName}, Managing Director has approved the disputed amount of remaining rupees ${finaliseAmount}.`
                const newComment: Comment = {
                    timeStamp: new Date().toISOString(),
                    comment: newMsg
                }
                oldComments?.push(newComment);
                const bodyObj = {
                    formId: FORMID.commentsId,
                    id:dataa.data.data[0].id,
                    formData:{
                     comments:oldComments,
                    }
                }
                await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, bodyObj);   
            }
        }
        catch (e) {
            console.log(e)
        }
    }


    const callNotification = async(message:string, data:any) => {
        try{
          let payload = {
            userId:data.referrerID,
            message:message,
            type:'fireBase'
          }
          let resp = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}insta/v1/notifications`, payload);
        }
        catch (e:any) {
          console.log(e);
          setMessage(e.message);
          setOpen(true);
        }
      }

    const converDate = (str: any) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleGeneratePayment = async () => {
        let newDate = new Date();
        try {
            let count = 0;
            docArray.forEach((data) => data.disputeAcceptanceStatusByMd === 'raised' && count++);
            if (count > 0) {
                console.log('All date are not processed, Please Approve / modify data to generate Payment')
            }
            else {
                const payload = {
                    processDefinitionKey: "Process_vshpz1m",
                    businessKey: "rfm-file",
                    variables: {
                        to: process.env.REACT_APP_PAYMENT_EMAIL,
                        generatedBy: localStorage.getItem('userName'),
                        approvedBy: 'dispute',
                        dateOfDischarge: converDate(newDate),
                    }
                }
                console.log(payload);
                let resp = await _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}workflow/v1/process/start`, payload);
                setMessage('Payment File Generated, Process started successfully');
                setOpen(true);
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }


    return (
        <div>
            <Snackbar
                ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                }}
                open={open}
                autoHideDuration={3000}
                message={message}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />

            {viewReport &&
                <>
                    <div className="overlay" onClick={closeWindow}></div>
                    <div className="modalPopup modalPopupw">
                        <h4>Payment Approval Report</h4>
                        <table className="data-grid-table">
                            <tbody>
                                <tr>
                                    <td className="text-right">Net Amount: </td>
                                    <td className="text-right">{reportData?.netAmount}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><b className="color444"> Amount after excluding bills</b></td>
                                </tr>
                                {getExcludingData(reportData?.amountCalculation)}
                                <tr>
                                    <td className="text-right">Amount Calculated after excluding above bills</td>
                                    <td className="text-right">{reportData?.amountCalculated}</td>
                                </tr>
                                <tr>
                                    <td className="text-right">Percentage of referrer on calculated amount</td>
                                    <td className="text-right">{reportData?.percentageOfReferrer}%</td>
                                </tr>
                                <tr>
                                    <td className="text-right"><b>Total</b></td>
                                    <td className="text-right"><b>{reportData?.referrerAmount}</b></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="amountView">
                            <p>Amount Paid: <span>{reportData?.finalisedAmount}</span></p>
                            <p>Amount in Dispute: <span>{reportData?.disputedAmount}</span></p>
                            <p>Amount Outstanding: <span>{calculateVal(reportData?.disputedAmount ?? 0, reportData?.finalisedAmount ?? 0)}
                                {/* {reportData?.disputedAmount?? - reportData?.finalisedAmount}*/}
                            </span></p>
                        </div>
                        <div className="approvalComment">
                            <label>Finalised Amount: </label>
                            <input type="text" value={finaliseAmount} onChange={handleFinaliseAmount} placeholder="Enter Finalised Amount" />
                            <label>Comment / Description</label>
                            <textarea className="verticalTop" onChange={handleDescription} placeholder="Enter Comment / Desciption"></textarea>
                            <div className="text-center"><button onClick={() => handleApprove(reportData, 0)}>Approve</button></div>
                        </div>
                    </div>
                </>
            }

            <div>
                <table className="data-grid-table">
                    <thead>
                        <tr>
                            {tableHead.map(d => <th>{d}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {docArray.length > 0 ?
                            docArray.map((d, i) => (
                                <tr>
                                    <td>{d.MR_NO}</td>
                                    <td>{d.patientName}</td>
                                    <td>{d.referrerName}</td>
                                    <td>{d.referrerMobileNumber}</td>
                                    <td>{d.finalisedAmount}</td>
                                    <td>
                                        <div className="modfiedTextAmt ">
                                            <span className="diff">{d.disputedAmount}
                                                <InfoIcon className="iconInfo" />
                                                <span className="descToolTip">{d.disputeDiscription}</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-center">{d.disputedAmount - d.finalisedAmount}</td>
                                    {/* <td onClick={() => handleViewReport(d, i)} className="text-center viewBtnModal">View </td> */}
                                    <td>
                                        {d.disputeAcceptanceStatusByMd === 'raised' &&
                                            <>
                                                <button className="btnHandle" onClick={() => handleViewReport(d, i)}>Approve</button>
                                                <button className="btnHandle failurBt" onClick={() => rejectAmount(d, i)}>Reject</button>
                                            </>
                                        }
                                        {d.disputeAcceptanceStatusByMd === 'rejected' && 'Rejected'}
                                        {d.disputeAcceptanceStatusByMd === 'approved' && 'Approved'}

                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className="text-center" colSpan={9}>No Data for the selected Date</td>
                            </tr>
                        }

                    </tbody>
                </table>
                <div className="flex-center">
                    {docArray.length > 0 && docArray.filter(d => d.disputeFinalisedAmount > 0).length === docArray.length &&
                        <Button className="gradientBtn" onClick={handleGeneratePayment}>Generate Payment File</Button>
                    }
                </div>
            </div>
        </div>
    )
}

export default DisputePaymentApprovalMD;