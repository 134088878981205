import React, { useEffect } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HttpService from "../../services/HttpService";
import { makeStyles } from '@mui/styles';
import { ReactComponent as UserImg } from './../../assets/images/user.svg';
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { Button, Snackbar } from "@mui/material";
import { FORMID, GRADIENTIMAGECOLOR, INSTACONFLICTSTABLEHEADDATA, MID_URL_FORM_RUNTIME_DATA } from "../../utils/constants";

const _axios = HttpService.getAxiosClient();

const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
      backgroundImage: GRADIENTIMAGECOLOR,
    }
}));

const columns: GridColDef[] = INSTACONFLICTSTABLEHEADDATA;

const InstaConflicts = () => {
    let selectedRows: GridSelectionModel;
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [expanded, setExpanded] = React.useState(false);
    const [cardIndex, setCardIndex] = React.useState<string | number>(Number);
    const [rows1, setRows1] = React.useState<any>([]);
    const [totalRows1, setTotalRows1] = React.useState<any>([]);
    const [instaId, setInstaId] = React.useState("");
    const [gotData, setGotData] = React.useState(false);
    const [rows, setRows] = React.useState<any[]>([]);
    const [selectIndex, setSelectIndex] = React.useState("");
    const [showModalPopup, setShowModalPopup] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState("");

    const [referallFormData, setReferallFormData] = React.useState<any>([]);

    useEffect(() => {
        fetchInstaData();
    }, [])

    const fetchInstaData = async () => {
        try {
            let data = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?filter=formData.patientStatus:dispute;formData.IS_INSTA_CONFLICT:true&formId=${FORMID.instaReferalPatientId}`);
            setRows1(data.data.data.map((d: any) => d.formData))
            setTotalRows1(data.data.data);
            setInstaId(data.data.data[0]?.id)
        }
        catch (e:any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
          }
    }

    const handleChange = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        // setExpanded(isExpanded ? index : false);
        if (cardIndex === index) {
            setExpanded(false);
            setCardIndex("");
        }
        else {
            setExpanded(true);
            setCardIndex(index);
            setInstaId(totalRows1[index].id);
            getReferralData(rows1[index].mobileNumber);
        }
    };

    const getReferralData = async (mobileNumber: string) => {
        const userId = localStorage.getItem('userId')
        try {
            let data = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?formId=${FORMID.procedureRefereInstaDataId}&filter=formData.IS_INSTA_CONFLICT:true;formData.MOBILE_NUMBER:${mobileNumber};formData.EXECUTIVE_ID:${userId}`);
            let tableData = data.data.data.filter((d: any) => d.formData);
            if (tableData.length === 0) {
                setGotData(false)
            }
            else {
                if (data && data.data.success) {
                    if (data?.data?.data?.length > 0) {
                        setRows(dt => data.data.data.map((d: any) => ({ id: d.id, ...d.formData })));
                        setGotData(true)
                    }
                }
            }
        }
        catch (e:any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }

    const handleSingleSelect = (data: any) => {
        if (data.length === 0) {
            setSelectIndex("");
        }
        else {
            setSelectIndex(data[0])
            selectedRows = data[0];
            setSelectedId(data[0])
        }
    }

    const transactionUpdateHandler = async() => {
        if (!selectIndex) {
           setShowModalPopup(true)
        }
        else {
            var selectedObj = rows.filter(d => d.id === selectedId);
            let selectedDataId: string = selectedObj[0].id;
            let nonSelectedIds = rows.filter(d => d.id !== selectedId).map(a => a.id);
            const payload = {
                validInstaRecordId: selectedDataId,
                validPatientId: instaId,
                invalidPatientIds: [],
                conflictType: 'instaConflict',
                inValidInstaRecordIds: nonSelectedIds
            }
            try{
                let resp = await _axios.put(`${process.env.REACT_APP_MAIN_SERVER_URL}insta/v1/admit-patients/conflict-update`, payload);
                setMessage('Successfully updated data to referrer');
                setOpen(true);
                fetchInstaData();
            }
            catch (e:any) {
                console.log(e);
                setMessage(e.message);
                setOpen(true);
            }  
        }
    }

    const deleteAllRecords = async() => {
        try{
            let nonSelectedIds = rows.filter(d => d.id !== selectedId).map(a => a.id);
            const payload = {
                invalidPatientIds: nonSelectedIds,
                conflictType: 'instaConflict',
                inValidInstaRecordIds: [instaId]
            }
            let res = await _axios.put(`${process.env.REACT_APP_MAIN_SERVER_URL}insta/v1/admit-patients/conflict-update`, payload);
                setMessage('Removed all data from referrer conflicts');
                setOpen(true);
                fetchInstaData();
        }
        catch (e:any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
        
    }
    const cancelModalPopup = () => {
        setShowModalPopup(false)
    }

    return (
        <div>
            <Snackbar
                ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyleViaContentProps
                }}
                open={open}
                autoHideDuration={3000}
                message={message}
                onClose={() => setOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            />

            {showModalPopup &&
                <>
                    <div className="overlay"></div>
                    <div className="modalPopup">
                        <h4>You havn't selected any patient details, Are you sure you want to remove all Patient data</h4>
                        <button className="yesRemoveBtn" onClick={deleteAllRecords}>Yes, Remove</button>
                        <button className="cancelBtn" onClick={cancelModalPopup}>Back</button>
                    </div>
                </>
            }
            <div className="tableCardHeader">
                <p className="ml26 widthP40">User Details</p>
                <p className="widthP20">Speciality Of Treatment</p>
                <p className="widthP20">Referral Name</p>
                <p className="widthP20">Conflict Type</p>
            </div>
            {rows1.length > 0  ?
            rows1.map((data: any, index: number) => (
                <Accordion key={index} expanded={expanded && cardIndex === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="customCardStyleHandler"
                    >
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>
                            <div className="profileCard">
                                <UserImg />
                                <div className="profileDetail">
                                    <h4>{data.name} </h4>
                                    <p className="mob">{data.mobileNumber}</p>
                                </div>
                            </div>
                        </Typography>
                        <Typography sx={{ width: '20%', color: 'text.secondary' }}>{data.specialityOfTreatment}</Typography>
                        <Typography sx={{ width: '20%', color: 'text.secondary' }}>{data.referralName}</Typography>
                        <Typography sx={{ width: '20%', color: 'text.secondary' }}>Insta</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="profileDataGrid">
                            {gotData ?
                                <>
                                    <div className="icgridp">
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={15}
                                            rowsPerPageOptions={[5]}
                                            checkboxSelection
                                            isRowSelectable={(params: any) => {
                                                if (selectIndex !== "") {
                                                    return params.row.id === selectIndex;
                                                }
                                                else {
                                                    return true;
                                                }
                                            }}
                                            onSelectionModelChange={(data) => handleSingleSelect(data)}
                                        />

                                    </div>
                                    <div className="flex-center">
                                        <Button className="gradientBtn" onClick={transactionUpdateHandler}> Save </Button>
                                    </div>
                                </>
                                :
                                <div className="text-center p40">
                                    Duplicate Data Already Processed...
                                </div>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            )):
            <div className="noDataIC">
                No Insta Conflicts 
            </div>
            }
        </div>
    )
}

export default InstaConflicts;