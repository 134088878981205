import React, { ReactElement, FC } from "react";
import {Button, CircularProgress, Snackbar} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField } from "@mui/material";
import '../App.css';
import KeycloakService from "../services/KeycloakService";
import axios from "axios";
import Otp from '../pages/Otp';
import {specialCharValidation, emailValidation} from '../constants';
import { GRADIENTIMAGECOLOR } from "../utils/constants";

const useStyles = makeStyles(() => ({
  inputField: {
     [`& fieldset`]: {
           borderRadius: 12,
     },
  },
  snackbarStyleViaContentProps: {
    backgroundImage: GRADIENTIMAGECOLOR,
  }
}));

const Signup: FC<any> = (): ReactElement => {

  const classes = useStyles();

  const [name, setName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [emailId, setEmailId] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidMobile, setInvalidMobile] = React.useState(false);
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const signupUser = async () => {
    const splitName = name.split(' ');
    const token = await KeycloakService.fetchTokenDifferently();
    const singupResponse = await axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}workflow/v1/process/start`,
    {
      "processDefinitionKey": "Process_844844770998710300",
      "businessKey": "santosh.n@techsophy.com",
      "variables": {
          "action": "add",
          "userData": JSON.stringify({
            firstName: splitName[0],
            lastName: splitName[1] ? name.slice(splitName[0].length): splitName[0],
            userName: '+91' + mobileNumber,
            mobileNumber: '+91' + mobileNumber,
            department: 'KIMS',
            emailId: emailId
          })
      }
    }  
    , {headers: {Authorization: `Bearer ${token}`}}).catch(err => {
      setMessage('Sorry some error while registration, please try again');
      setOpen(true);
      console.log('eeee', err);
    });
    if(singupResponse) {
      setName('');
      setEmailId('');
      setMobileNumber('');
      setMessage('Successfully registered the user, please login with registered mobile number');
      setOpen(true);
      setTimeout(() => window.open('/'), 3000)
    }
  };

  const sendOTP = async () => {
    if(!emailValidation(emailId)) {
      setInvalidEmail(true);
      setTimeout(() => setInvalidEmail(false), 3000);
      return false;
    } else if(Number(mobileNumber) < 6000000000 ||
        Number.isNaN(Number(mobileNumber)) || 
        mobileNumber.length !== 10) {
      setInvalidMobile(true);
      setTimeout(() => setInvalidMobile(false), 3000);
      return false;
    }
    setLoader(true);
    const token = await KeycloakService.fetchTokenDifferently();
    const otpSentResponse = await axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}accounts/v1/otp/generate`,
    {
      "channel":"mobile",
      "to": '+91' + mobileNumber,
      "from":"+91770923418",
      "subject":"KIMS RMS APPLICATION",
      "body":"Dear KIMS RMS user, please find your otp : ${otp}",
    }  
    , {headers: {Authorization: `Bearer ${token}`}}).catch(err => {
      console.log('eeee', err);
      setLoader(false);
      setMessage('Sorry some error in generating OTP');
      setOpen(true);
    });
    if(otpSentResponse?.data?.success) {
      setLoader(false);
      setOtpScreen(true)
    };
  }

  return (
      <>
      {!otpScreen ? 
        <div style={{fontSize: '1.3rem', color: '#717982'}}>
          <div className="side-content">
            <div className="side-content-body">
                <Snackbar
                  // ContentProps={{
                  //   "aria-describedby": "message-id",
                  //   className: classes.snackbarStyleViaContentProps
                  // }}
                  open={open}
                  autoHideDuration={3000}
                  message={message}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                />
                <img  src="assets/images/kims_logo.png" alt="logo" style={{height: '60px', marginTop:'30px'}} />
                <p style={{fontSize: '25px', marginTop: '9vh', marginBottom: '6vh'}}>Sign Up</p>
                <div className="text-input">
                    <TextField
                      id="name"
                      label="Name*"
                      className={classes.inputField}
                      fullWidth
                      value={name}
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => {
                        if(!specialCharValidation(e.target.value)) return false;
                        setName(e.target.value)
                      }}
                    />
                </div>
                <div className="text-input">
                    <TextField
                      id="email"
                      error={invalidEmail}
                      label="Email*"
                      fullWidth
                      className={classes.inputField}
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                </div>
                <div className="text-input">
                    <TextField
                      id="Phone"
                      label="Phone*"
                      placeholder="9123092934"
                      error={invalidMobile}
                      fullWidth
                      className={classes.inputField}
                      inputProps={{ maxLength: 10 }}
                      value={mobileNumber}
                      onChange={(e) => {
                        const regex = /^[0-9\b]+$/;
                        if(!regex.test(e.target.value)) return false;
                        setMobileNumber(e.target.value)
                      }}
                    />
                </div>
                <div style={{textAlign:'center'}}>
                  {
                    loader ? 
                      <CircularProgress style={{color: '#CF3438'}}/>
                      :  <Button fullWidth variant="contained" 
                          onClick={sendOTP}
                          style={{backgroundColor: '#CF3438', height: '56px', borderRadius: '15px'}}
                          disabled={!name || !emailId || !mobileNumber}>
                            Send OTP
                        </Button>
                  }
                </div>
                <div style={{color: '#CF3438'}}>
                  {invalidEmail ? <small>Enter valid email</small> : null}
                  {invalidMobile ? <small>Enter valid phone no</small> : null}
                </div>
                <div style={{marginTop:'50px', textAlign: 'center'}}>
                  <span style={{fontSize: '13px'}}> Already have an account? </span>
                  <a href="/" style={{color: '#CF3438', textDecoration: 'none', fontSize: '15px'}}> Login</a>
                </div>
            </div>
          </div>
          <div className="side-image">
            <h2 className="side-image-text">KIMS Facilitates with Best in class</h2>
            <h2 className="side-image-text">Emergency Response Team</h2>
          </div>      
        </div>
        : <Otp setOtpScreen={setOtpScreen} mobileNumber={mobileNumber} signUpUser={signupUser}></Otp>
      }
      </>
  );
};

export default Signup;