import React, { ReactElement, FC } from "react";
import { Button, CircularProgress, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField, InputAdornment } from "@mui/material";
import '../App.css';
import KeycloakService from "../services/KeycloakService";
import axios from "axios";

const useStyles = makeStyles(() => ({
  inputField: {
     [`& fieldset`]: {
           borderRadius: 12,
     },
  },
  input: {
    "&::placeholder": {
      textAlign: "center"
    }
  }
}));

const Otp: FC<any> = (props): ReactElement => {
  const classes = useStyles();

  const [otp, setOTP] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const verifyOTP = async () => {
    setLoader(true);
    const token = await KeycloakService.fetchTokenDifferently();
    const verifyResponse = await axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}accounts/v1/otp/validate`,
    {
      "channel":"mobile",
      "to": '+91' + props.mobileNumber,
      "otp": otp
    }  
    , {headers: {Authorization: `Bearer ${token}`}}).catch(err => {
        setLoader(false);
        setMessage('Sorry some error in verifying OTP');
        setOpen(true);
        console.log('eeee', err)
    });
    if(verifyResponse?.data?.success) {
      setLoader(false);
      props.setOtpScreen(false);
      props.signUpUser();
    }
  };

  const sendOTP = async () => {
    setLoader(true);
    const token = await KeycloakService.fetchTokenDifferently();
    const otpSentResponse = await axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}accounts/v1/otp/generate`,
    {
      "channel":"mobile",
      "to": props.mobileNumber,
      "from":"+91770923418",
      "subject":"KIMS RMS APPLICATION",
      "body":"Dear KIMS RMS user, please find your otp : ${otp}",
    }  
    , {headers: {Authorization: `Bearer ${token}`}}).catch(err => {
      setLoader(false);
      setMessage('Sorry some error in verifying OTP');
      setOpen(true);
      console.log('eeee', err)
    });
    if(otpSentResponse?.data?.success) {
      setLoader(false);
    };
  }

  return (
    <div style={{fontSize: '1.3rem', color: '#717982'}}>
      <div className="side-content">
        <div className="side-content-body">
            <Snackbar
              open={open}
              autoHideDuration={3000}
              message={message}
              onClose={() => setOpen(false)}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            />
            <img  src="assets/images/kims_logo.png" alt="logo" style={{height: '60px', marginTop:'30px'}} />
            <p style={{fontSize: '25px', marginTop: '18vh', marginBottom: '5vh'}}>Enter OTP</p>
            <div className="text-input" style={{paddingLeft: '3vw', paddingRight: '3vw'}}>
                <TextField
                  id="name"
                  placeholder="* * * * *"
                  inputProps={{style: { textAlign: 'center' }, classes: {input: classes.input}}}
                  className={classes.inputField}
                  type="password"
                  fullWidth
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                />
            </div>
            <div>
              <Button 
              onClick={sendOTP}
              style={{textDecoration: 'underline', color: '#CF3438', marginBottom: '4vh'}}>
                RESEND OTP
              </Button>
            </div>
            <div style={{textAlign:'center'}}>
              {
                loader ? 
                  <CircularProgress style={{color: '#CF3438'}}/>
                  :  <Button fullWidth variant="contained" 
                      onClick={verifyOTP}
                      style={{backgroundColor: '#CF3438', height: '56px', borderRadius: '15px'}}
                      disabled={!otp}>
                        Verify OTP
                    </Button>
              }
            </div>
            <div style={{marginTop:'50px', textAlign: 'center'}}>
              <span style={{fontSize: '13px'}}> Already have an account? </span>
              <a href="/login" style={{color: '#CF3438', textDecoration: 'none', fontSize: '15px'}}> Login</a>
            </div>
        </div>
      </div>
      <div className="side-image">
        <h2 className="side-image-text">KIMS Facilitates with Best in class</h2>
        <h2 className="side-image-text">Emergency Response Team</h2>
      </div>      
    </div>
  );
};

export default Otp;