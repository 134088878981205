import React, { useEffect, useState } from "react";
import HttpService from '../services/HttpService';
import { makeStyles } from '@mui/styles';
import { Snackbar } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { smsService } from "../services/smsService";
import { FORMID, GRADIENTIMAGECOLOR, MID_URL_FORM_RUNTIME_DATA } from "../utils/constants";
import { reportDataObj,docsData } from "../types/Module_Type";


const _axios = HttpService.getAxiosClient();

const tableHead = ['MR_No', 'Patient Name', 'Referrer Name', 'Referrer Mobile', 'Amount Paid', 'Amount in Dispute ', 'View Calculation', 'Amount OutStanding', 'Action'];


const useStyles = makeStyles(() => ({
    snackbarStyleViaContentProps: {
        backgroundImage: GRADIENTIMAGECOLOR,
    }
}));





const DisputePaymentApproval = () => {
    const classes = useStyles();

    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [docArray, setDocArray] = useState<docsData[]>([]);
    const [viewReport, setViewReport] = useState(false);
    const [reportData, setReportData] = useState<reportDataObj | null>(null);

    useEffect(() => {
        fetchAllDisputeRecords();
    }, [])




    const fetchAllDisputeRecords = async () => {
        const userId = localStorage.getItem('userId')
        try {
            let res = await _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}?page=0&size=10000&formId=1030055364952023040&filter=formData.paymentAcceptanceStatusByreferrer:"rejected";formData.disputeAcceptanceStatusByManager:"raised";formData.disputeFileGenerationStatus:false;formData.managerId:${userId}`);
            if (res && res.data.success) {
                if (res?.data?.data?.content?.length > 0) {
                    // setRows(data => res.data.data.map((d: any) => ({ id: d.id, ...d.formData })));
                    setDocArray(res.data.data.content.map((d: any) => ({ id: d.id, ...d.formData })));
                }
                else{
                    setDocArray([])
                }
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e?.message);
            setOpen(true);
        }
    }

    const handleViewReport = (d: any, i: any) => {
        setViewReport(true);
        setReportData(d ?? {})
    }

    const closeWindow = () => {
        setViewReport(false);
    }

    const getExcludingData = (excludingData: any) => {
        let newArray = Object.keys(excludingData).map(name => ({ name, value: excludingData[name] }))
        return (
            newArray.map(arObj => <tr><td className="text-right">{arObj.name} :</td><td className="text-right">{arObj.value}</td></tr>)
        )
    }

    const approveAmount = async(data:any, index:number) => {
        try{
            const payload = {
                id: data.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    disputeAcceptanceStatusByManager:'approved'
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            setMessage('Referrer Amount Approved.');
            setOpen(true);
            if (resp.data.success) {
                setTimeout(() => {                    
                    fetchAllDisputeRecords();
                }, 1500);
                let msg = `Dear ${localStorage.getItem('MDName')}, mananger with name ${data.managerName} has approved a disputed payment for ${data.referrerName}, patient Name:${data.patientName}, disputed Amount:${data.disputedAmount} `;
                smsService('managingDirector', msg)
            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }
    const rejectAmount = async(data:any, index:number) => {
        try{
            const payload = {
                id: data.id,
                formId: FORMID.paymentAcceptanceTranscationId,
                formData: {
                    disputeAcceptanceStatusByManager:'rejected',
                    disputeAcceptanceStatusByMd:'rejected',
                    disputePaymentAcceptanceStatus:"rejected"
                }
            }
            let resp = await _axios.patch(`${process.env.REACT_APP_MAIN_SERVER_URL}${MID_URL_FORM_RUNTIME_DATA}`, payload);
            if (resp.data.success) {
                setTimeout(() => {
                    setMessage('Referrer Amount Rejected.');
                    setOpen(true);
                    fetchAllDisputeRecords();
                }, 1500)

            }
        }
        catch (e: any) {
            console.log(e);
            setMessage(e.message);
            setOpen(true);
        }
    }


    return (
        <div>
            <Snackbar
                ContentProps={{
                    "aria-describedby": "message-id",
                    className: classes.snackbarStyleViaContentProps
                }}
                open={open}
                autoHideDuration={3000}
                message={message}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />

            {viewReport &&
                <>
                    <div className="overlay" onClick={closeWindow}></div>
                    <div className="modalPopup modalPopupw">
                        <h4>Payment Approval Report</h4>
                        <table className="data-grid-table">
                            <tbody>
                                <tr>
                                    <td className="text-right">Net Amount: </td>
                                    <td className="text-right">{reportData?.netAmount}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><b className="color444"> Amount after excluding bills</b></td>
                                </tr>
                                {getExcludingData(reportData?.amountCalculation)}
                                <tr>
                                    <td className="text-right">Amount Calculated after excluding above bills</td>
                                    <td className="text-right">{reportData?.amountCalculated}</td>
                                </tr>
                                <tr>
                                    <td className="text-right">Percentage of referrer on calculated amount</td>
                                    <td className="text-right">{reportData?.percentageOfReferrer}%</td>
                                </tr>
                                <tr>
                                    <td className="text-right"><b>Total</b></td>
                                    <td className="text-right"><b>{reportData?.referrerAmount}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }

            <div>
                <table className="data-grid-table">
                    <thead>
                        <tr>
                            {tableHead.map(d => <th>{d}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {docArray.length > 0 ?
                            docArray.map((d, i) => (
                                <tr>
                                    <td>{d.MR_NO}</td>
                                    <td>{d.patientName}</td>
                                    <td>{d.referrerName}</td>
                                    <td>{d.referrerMobileNumber}</td>
                                    <td>{d.finalisedAmount}</td>
                                    <td>
                                        <div className="modfiedTextAmt ">
                                            <span className="diff">{d.disputedAmount}
                                                <InfoIcon className="iconInfo" />
                                                <span className="descToolTip">{d.disputeDiscription}</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-center">{d.disputedAmount - d.finalisedAmount}</td>
                                    <td onClick={() => handleViewReport(d, i)} className="text-center viewBtnModal">View </td>
                                    <td>
                                        {d.disputeAcceptanceStatusByManager === 'raised' && 
                                        <>
                                            <button className="btnHandle" onClick={() => approveAmount(d, i)}>Approve</button>
                                            <button className="btnHandle failurBt" onClick={() => rejectAmount(d,i)}>Reject</button>
                                        </>
                                        }
                                        {d.disputeAcceptanceStatusByManager === 'rejected' && 'Rejected' }
                                        {d.disputeAcceptanceStatusByManager === 'approved' && 'Approved' }
                                        
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className="text-center" colSpan={9}>No Data for the selected Date</td>
                            </tr>
                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DisputePaymentApproval;